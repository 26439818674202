// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;

const initMapbox = () => {
  const mapElement = document.getElementById('map');
  const fitMapToMarkers = (map, markers) => {
    const bounds = new mapboxgl.LngLatBounds();
    markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
    map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
  };
  if (mapElement) { // only build a map if there's a div#map to inject into
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/youssefmnk/ckwut7tbz87s514s9gbq3psiq', // style URL
      center: [	6.1319346, 49.611621], // starting position ([lng, lat] for Mombasa, Kenya)
      zoom: 3.4 // starting zoom
      
    });
    document.querySelectorAll('.asset-card').forEach((asset)=> {
      asset.addEventListener('click', () => {
        // const assetElement = document.getElementById('asset-card')
        // console.log(assetElement.dataset)

        const asset_coords = JSON.parse(asset.dataset.marker);
        map.fitBounds([
          [asset_coords[0].lng - 0.5, asset_coords[0].lat - 0.5],
          [asset_coords[0].lng + 0.5, asset_coords[0].lat + 0.5]
        ]);
      });
    });
      
    document.getElementById('fit').addEventListener('click', () => {
      map.fitBounds([
        [-11.31, 37.90], // southwestern corner of the bounds
        [25.279652, 54.687157] // northeastern corner of the bounds
      ]);
    });

    const markers = JSON.parse(mapElement.dataset.markers);
    markers.forEach((marker) => {
    const popup = new mapboxgl.Popup().setHTML(marker.info_window);
    new mapboxgl.Marker()
      .setLngLat([ marker.lng, marker.lat ])
      .setPopup(popup) // add this
      .addTo(map);
  });
  }
};

export { initMapbox };