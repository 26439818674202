// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "handswipe", "chevronDown1","chevronDown2", "chevronDown3", "chevronLeft1", "chevronLeft2", "chevronLeft3", "bulletinSouscription",
  "faq1", "faq2", "faq3" ]

  range(start, end) {
    /* generate a range : [start, start+1, ..., end-1, end] */
    let len = end - start + 1;
    let a = new Array(len);
    for (let i = 0; i < len; i++) a[i] = start + i;
    return a;
  };

  connect() {
    const handswipe = this.handswipeTarget;
    const assetTable = document.getElementById('asset-card')
    assetTable.ontouchmove = function() {
      if (this.getBoundingClientRect()["x"] < -100) {
        handswipe.classList.add("d-none");
      } else {
        handswipe.classList.remove("d-none");
      }
      // console.log(this.getBoundingClientRect()["x"]);
    }
  }

  showFAQ1() {
    this.chevronDown1Target.classList.toggle("d-none");
    this.chevronLeft1Target.classList.toggle("d-none");
    this.faq1Target.classList.toggle("d-none");
  }
  showFAQ2() {
    this.chevronDown2Target.classList.toggle("d-none");
    this.chevronLeft2Target.classList.toggle("d-none");
    this.faq2Target.classList.toggle("d-none");
  }
  showFAQ3() {
    this.bulletinSouscriptionTarget.classList.toggle('document-element-fund-last');
    this.bulletinSouscriptionTarget.classList.toggle('document-element-fund');
    this.chevronDown3Target.classList.toggle("d-none");
    this.chevronLeft3Target.classList.toggle("d-none");
    this.faq3Target.classList.toggle("d-none");
  }

  showQuarters() {
    const yearId = window.event.srcElement.id;
    const yearDiv = document.getElementById(yearId);
    const quartersDiv = document.getElementById("quarters-" + yearId);
    const quartersClass = quartersDiv.classList;
    const yearClass = yearDiv.classList;
    const years = this.range(2019, new Date().getFullYear())

    if ( quartersClass.contains('d-none')) {
      years.forEach( year => {
        document.getElementById('bti' + year).classList.remove("bti-year-clicked");
        document.getElementById('quarters-bti' + year).classList.add('d-none');
      });
      yearClass.toggle("bti-year-clicked");
      quartersClass.toggle('d-none');
    } else {
      quartersClass.toggle("d-none");
      yearClass.toggle("bti-year-clicked");
    };
  }

  showDocsFunds() {
    const personneBtnId = window.event.srcElement.id;
    const docsDivId = 'doc-personne-'  + personneBtnId;
    const personneBtn = document.getElementById(personneBtnId);
    const docsDiv = document.getElementById(docsDivId);
    let otherPersonneDiv = personneBtn.nextElementSibling;
    if (personneBtn.previousElementSibling) {
      otherPersonneDiv = personneBtn.previousElementSibling;
    }
    const otherPersonneDocsId = 'doc-personne-' + otherPersonneDiv.id;
    const otherPersonneDocs = document.getElementById(otherPersonneDocsId);

    if (docsDiv.classList.contains('d-none')) {
      docsDiv.classList.toggle('d-none');
      personneBtn.classList.toggle('clicked-subscription-docs-titles');
      otherPersonneDiv.classList.remove('clicked-subscription-docs-titles');
      otherPersonneDocs.classList.add('d-none');
    } else {
      docsDiv.classList.toggle('d-none');
      personneBtn.classList.toggle('clicked-subscription-docs-titles');
    }
  }

  showCloud() {
    const docId = window.event.srcElement.id;
    const cloudId = 'cloud' + docId;
    const docTitle = document.getElementById(docId);
    const cloud = document.getElementById(cloudId);
    if (docTitle && cloud) {
      docTitle.classList.toggle('clicked-subscription-docs-titles');
      cloud.classList.toggle('d-none');
    }
  }
}
